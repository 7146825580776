<template>
    <div>
      <v-container v-if="screen > 800">
        <Desktop />
      </v-container>
      <v-container v-else>
        <Mobile />
      </v-container>
    </div>
  </template>
  

<script>
import ShieldService from "../services/shield.service";
import swal from "sweetalert";
import Desktop from "../components/finishDesktop.vue";
import Mobile from "../components/finishMobile.vue";
// import axios from 'axios'

export default {
  components: { Desktop, Mobile },
  data() {
    return {
      success: null,
      id: null,
      insurance: null,
      load: false,
      person: {
        name: null,
        email: null,
      },
      screen: window.screen.width,
      contactID: null,
      planData: null,
      userData: {
        couponId: null,
        salud: null,
        typePlan: null,
      },
      edadMayor: null,
    };
  },
  methods: {
    returnPage() {
      window.location.href = process.env.VUE_APP_REDIRECTION;
    },
  },
  async mounted() {
    //Obtener Parametro migrates
    //crear vif velse
    const query = this.$route.query;
    if (query) {
      if (query.success === "true") {
        this.userData.typePlan = sessionStorage.getItem("typePlan");
        this.userData.salud = sessionStorage.getItem("salud");
        this.userData.couponId =
          sessionStorage.getItem("couponId") == "null"
            ? null
            : sessionStorage.getItem("couponId");
        this.edadMayor = sessionStorage.getItem("edadMayor");

        this.planData = await ShieldService.getPlans(
          this.userData.salud,
          this.userData.typePlan,
          this.edadMayor,
          this.userData.couponId
        );

        // const personId = sessionStorage.getItem('personIdWaitingForToku')
        this.success = true;
        // this.id = query.id ?? personId
        // this.person = await ShieldService.getPeople(this.id);

        this.person.name = sessionStorage.getItem("nameFinishMigracion");
        this.person.email = sessionStorage.getItem("emailFinishMigracion");

        this.contactID = sessionStorage.getItem("contactID");

        if (this.contactID) {
          const shieldRes = await ShieldService.updateContactActiveCampain(
            null,
            this.planData[0],
            this.contactID,
            3
          );
          console.log(shieldRes.data);
        }

        swal({
          title: "¡Has contratado tu seguro con éxito! ",
          text: "Revisa tu correo electrónico para obtener toda la información.",
          icon: "success",
          button: "Revisar detalle",
        });

        this.load = true;
      } else if (query.success === "false") {
        this.success = false;
      }
    }
  },
  // async mounted() {
  //     const query = this.$route.query;
  //     if (query) {
  //         if (query.success === "true") {
  //             this.success = true;
  //             this.id = query.id

  //             swal({
  //                 title: "¡Has contratado tu seguro con éxito! ",
  //                 text: "Revisa tu correo electrónico para obtener toda la información.",
  //                 icon: "success",
  //                 button: "Revisar detalle",
  //             });

  //             this.insurance = insurance.data.data

  //             this.person = await ShieldService.getPeople(this.insurance.person
  //             );

  //             this.load = true

  //         } else if (query.success === "false") {
  //             this.success = false;
  //         }
  //     }
  // },
};
</script>

<style>
.v-alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, 100%);
  margin: 0 auto;
}

.fontStyle{


font-family: 'Inter';
font-style: normal;

color: #333333;
}

html
  body
  div#app.v-application.v-application--is-ltr.theme--light
  div.v-application--wrap
  main.v-main
  div.v-main__wrap
  div.container
  div.row
  div.text-center.col
  div.mx-auto.v-card.v-sheet.theme--light.elevation-2
  div.text-center.col {
  margin: auto;
}
</style>
